@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  letter-spacing: -0.05em !important;
  --pcdblue: #0d79ec;
  --pcdwhite: #ffffff;
  --pcdblack: #000000;
  --pcdoffwhite: #f2f2f2;
  --pcdoffblack: #1f1f1f;
  --pcdgray: #999d9e;

}

html {
  scroll-behavior: smooth !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* SCROLLBAR */
::-webkit-scrollbar {
  background: transparent !important;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #0d79ec;
}

.text-hero-transparent {
  -webkit-text-stroke: 1px #1f1f1f;
  font-family: Helvetica, sans-serif !important;
  letter-spacing: -0.05em !important;
}

.text-hero-transparent:hover {
  -webkit-text-stroke: 0px #1f1f1f;
}
